.banner-page-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .banner-page-list {
    border-radius: 15px;
    width: 100%;
    overflow: hidden;

    .banner {
      max-width: 100%;
      max-height: 40px;
      width: auto;
      height: auto;
    }
  }
  .banner-page-paging {
    width: 100%;
    padding: 40px 0;
  }
}

.banner-modal {
  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .banner-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  .label-input {
    margin-right: 10px;
    display: flex;
    display: flex;
    flex-direction: column;
    label {
      color: var(--primary-text-color);
      font-size: 12px;
      font-family: 'Poppins-Medium';
      padding: 10px 3px;
    }

    input,
    textarea {
      font-size: 14px;
      font-family: 'Poppins-Regular';
      height: 40px;
      width: 200px;
      border-radius: 5px;
      background-color: var(--background-3);
      &:disabled {
        background-color: var(--primary-disable-color);
      }
    }

    .ant-select-selector {
      font-size: 14px;
      font-family: 'Poppins-Regular';
      height: 40px;
      width: 200px;
      border-radius: 5px;
      align-items: center;
    }

    textarea {
      min-height: 175px;
    }
  }

  .banner-area {
    display: flex;
    flex-direction: row;
    color: var(--primary-text-color);
    width: 100%;

    .banner-item {
      flex: 1;
      display: flex;
      flex-direction: column;

      label {
        color: var(--primary-text-color);
        font-size: 12px;
        font-family: 'Poppins-Medium';
        padding: 10px 3px;
      }

      .banner-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .banner-image {
          width: 480px;
          height: 180px;
          background-color: var(--background-3);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }

        .note {
          color: var(--primary-text-color);
          font-size: 12px;
          font-family: 'Poppins-Regular';
          padding: 10px 3px;
        }
        .upload-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          color: var(--primary-btn-text-color);
          font-size: 12px;
          font-family: 'Poppins-Medium';
          padding: 10px 3px;
          text-decoration: underline;
          margin-left: 20px;
          width: 140px;
          height: 32px;
          border-radius: 5px;
          background-color: var(--primary-btn-color);
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .create-banner-btn {
    width: 255px;
    height: 36px;
    margin-top: 30px;
    background-color: var(--primary-btn-color);
    border-radius: 5px;
    border: none;
    font-size: 11px;
    font-family: 'Poppins-Medium';
    color: var(--primary-btn-text-color);
  }
}
