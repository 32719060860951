.task-detail {
  background-color: var(--task-background);
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  color: var(--primary-text-color);
  font-size: 12;
  font-weight: 500;

  .task-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    .task-header-item {
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      &.report {
        height: 100%;
        justify-content: flex-end;
      }

      .task-header-label {
        margin-bottom: 5px;
      }

      .task-header-code {
        width: 110px;
        height: 24px;
        border-radius: 5px;
        background-color: #c7c7c7;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .task-header-status {
        height: 24px;
        border-radius: 5px;
        text-align: center;
        color: white;
        padding: 0 12px;
      }

      .task-header-report {
        text-decoration: underline;
        cursor: pointer;
        color: #dc3d21;
      }
    }
  }

  .task-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    .task-info-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      .task-info-item {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-bottom: 15px;
        span {
          margin-bottom: 5px;
        }

        .phone-detail {
          display: flex;
          gap: 8px;

          &-code {
            width: 120px;
          }

          &-number {
            width: 100%;
          }
        }

        input,
        select {
          border-radius: 10px;
          padding: 0 10px;
          color: black;
          height: 40px;
          &:disabled {
            background-color: var(--primary-disable-color);
            color: black;
          }
        }

        textarea {
          border-radius: 10px;
          &:disabled {
            background-color: var(--primary-disable-color);
            color: black;
          }
        }

        .date-picker {
          height: 40px;
          border-radius: 10px;

          input {
            text-align: center;
            font-weight: 700;
            font-size: 13px;
          }
        }

        .ant-picker {
          border-radius: 10px;
          height: 40px;
          width: 100%;
          padding: 0;
        }

        .ant-select-disabled {
          .ant-select-selector {
            background-color: var(--primary-disable-color);
            color: black;
          }
        }

        .ant-select-selector {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 40px;
          width: 180;
          border-radius: 10px;
          border: none;
          padding-left: 20px;
          padding-right: 20px;
          overflow: hidden;

          .ant-select-selection-item {
            padding: 0;
            margin: 0;
          }
        }
        .ant-select-arrow {
          display: none;
        }

        .ant-picker-disabled {
          background-color: var(--primary-disable-color);
          color: black;
          border: none;
        }

        .disabled {
          .ant-select-selector {
            background-color: var(--primary-disable-color);
            .ant-select-selection-placeholder {
              color: black;
            }
          }
        }

        .reassign-needed {
          border: 2px solid #c16c6e !important;
          border-radius: 12px;

          .ant-select-selector {
            background: #fee6e9;

            .ant-select-selection-placeholder {
              color: #c16c6e;
            }
          }
        }
      }

      .task-info-appointment {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
        gap: 16px;
        .task-info-item {
          flex: 1;
        }

        @media screen and(max-width: 1300px) {
        }
      }

      .edit-btn {
        font-size: 11px;
        font-family: 'Poppins-Medium';
        color: var(--primary-text-color);
        text-decoration: underline;
        padding-right: 30px;
        cursor: pointer;
        background-color: inherit;
        width: 255px;
        height: 36px;
        margin-top: 10px;
        border-radius: 5px;
        border: 1px solid var(--primary-text-color);
      }

      .save-btn {
        width: 255px;
        height: 36px;
        margin-top: 10px;
        background-color: var(--primary-btn-color);
        border-radius: 5px;
        border: none;
        font-size: 11px;
        font-family: 'Poppins-Medium';
        color: var(--primary-btn-text-color);
        margin-right: 10px;
      }
    }
  }
}

.technician-report {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  color: var(--primary-text-color);

  .left {
    display: flex;
    flex-direction: column;
    flex: 1;

    label {
      font-size: 12px;
      margin-bottom: 5px;
      margin-top: 15px;
    }
    .job-detail {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      background-color: var(--background-3);
      border-radius: 10px;
      color: black;
      padding: 10px;

      .item {
        display: flex;
        flex-direction: column;
        min-width: 28%;
        margin-bottom: 10px;
        font-size: 12px;
        margin-right: 20px;

        label {
          color: black;
          font-weight: 600;
          margin: 0;
        }

        &.status {
          div {
            width: 110px;
            height: 24px;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #552d26;
            color: white;
          }
        }
      }
    }

    .service-info,
    .technician-remark {
      background-color: var(--background-3);
      border-radius: 10px;
      color: black;
      padding: 10px;
      white-space: pre-wrap;
    }
    .print {
      cursor: pointer;
      text-decoration: underline;
      color: var(--primary-text-color);
      margin-top: 10px;
    }

    .label-input {
      margin: 0 8px;

      .paymentMethod {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;

        width: 160px;
        height: 42px;
        background-color: var(--background-3);
        border-radius: 10px;
        color: var(--secondary-text-color);
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 340px;
    margin-left: 24px;
    color: var(--primary-text-color);

    label {
      font-size: 12px;
      margin-bottom: 5px;
      margin-top: 15px;
    }
    .technician-info {
      background-color: var(--background-3);
      border-radius: 10px;
      color: black;
      padding: 10px;
    }

    .time-info {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      margin-top: 15px;
      .time-item {
        display: flex;
        flex-direction: column;
        flex: 1;
        &:first-child {
          margin-right: 10px;
        }

        label {
          font-size: 12px;
        }
        div {
          background-color: var(--background-3);
          border-radius: 10px;
          color: black;
          padding: 10px;
        }
      }
    }

    .images {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;

      img {
        border-radius: 2px;
      }
    }

    .signature-info {
      flex: 1;
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      margin-top: 15px;

      .signature-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        label {
          font-size: 12px;
        }
        img {
          border-radius: 2px;
          background-color: var(--background-3);
        }
      }
    }

    .completed-time {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      margin-bottom: 5px;
      margin-top: 15px;

      label {
        margin-right: 20px;
      }
      div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: var(--background-3);
        color: black;
        border-radius: 5px;
        width: 180px;
        height: 40px;
      }
    }
  }

  img {
    max-width: 50px;
    max-height: 50px;
  }
}
